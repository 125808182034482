:root {
  --mainBgColor: #fffffb;
  --mainGrey: #f2f2f2;
  --lightGrey: #ffffff;
  --darkGrey: #bfbfbf;
  --mainBlue: #244c64;
  --lightBlue: #527892;
  --darkBlue: #00243a;
  --mainRed: #ad3f35;
  --lightRed: #e46e5f;
  --darkRed: #78090f;
}

html,
body {
  height: 100%;
}

body {
  background-color: #fffffb;
  font-family: 'EB Garamond', serif;
  font-size: 22px;
  margin: 0px;
}

.app {
  height: 100%;
}

#root {
  height: 100%;
}

.well {
  color: var(--lightBlue);
  font-size: 1.8rem;
  display: inline-block;
  font-weight: 900;
}

p {
  line-height: 1.5;
}

a {
  color: var(--mainRed);
  font-weight: bolder;
  text-decoration: none;
  transition: 0.2s;
  transition-timing-function: linear;
}
a:hover {
  color: var(--lightBlue);
}

ul {
  list-style: circle;
  text-transform: capitalize;
}

/* Pricelist Table */
#pricelist {
  border-collapse: collapse;
  border: solid 1px;
  text-align: left;
  width: 100%;
}

#pricelist th,
#pricelist td {
  padding: 10px;
  border: 1px solid var(--darkBlue);
}

#pricelist th {
  background-color: var(--lightBlue);
  color: white;
  font-weight: bolder;
}

.acupunctuur__image-text {
  display: flex;
  flex-direction: column;
}

.acupunctuur__image img {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .acupunctuur__image-text {
    display: block;
  }

  .acupunctuur__image-text h2 {
    margin-top: 0;
  }

  .acupunctuur__image {
    float: right;
    margin-left: 40px;
    width: 50%;
  }
}

.logo-banner {
  height: fit-content;
  display: flex;
  gap: 20px;
  overflow-x: auto;
}

.logo-banner a {
  all: unset;
  cursor: pointer;
}

.logo-banner img {
  box-sizing: content-box;
  height: 100px;
  border: solid 1px var(--mainBlue);
  border-radius: 5px;
  padding: 5px;
  background-color: #fffeff;
}
